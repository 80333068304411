import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useRouter } from 'next/router';
import Image from 'next/legacy/image';
import styled from 'styled-components';
import _Link from 'next/link';

import { Layout } from 'src/styles/layout';
import { Color } from 'src/styles/colors';
import { BREAK_POINT } from 'src/constants/intro';
import useJoiningStore from 'src/states/joining';
import HamburgerMenu from './HamburgerMenu';

export const INTRO_ROUTES = {
  'about-us': 'About us',
  groups: 'Groups',
  playdates: 'Playdates',
  blog: 'Blog',
  contact: 'Contact',
} as const;

// 문자열에서 첫 번째 '/' 바로 뒤에 '/blog'가 오는지 체크하고, 두 번째 '/'부터는 모두 무시
const PATTERN = /^[^\/]*\/blog.*/;

const GlobalNavigationBar = () => {
  const router = useRouter();
  const pathname = router.pathname;
  const endPath = PATTERN.test(pathname) ? 'blog' : pathname.split('/')[2];
  const [openMenu, setOpenMenu] = useState(false);

  const showModal = useJoiningStore((state) => state.showModal);

  const closeMenu = () => {
    setOpenMenu(false);
  };

  return (
    <GNBContainer openMenu={openMenu}>
      <Link href="/intro">
        <ImageWrapper>
          <LogoImage
            src={'/resources/images/intro-logo.png'}
            alt={'Meet My Dog Challenge Logo'}
            layout={'fill'}
          />
        </ImageWrapper>
      </Link>

      <MenuLayout>
        <Link href={'/intro'}>
          <MenuText selected={!endPath}>Home</MenuText>
        </Link>
        {Object.keys(INTRO_ROUTES).map((key) => {
          const route = key as keyof typeof INTRO_ROUTES;
          return (
            <Link key={route} href={`/intro/${route}`}>
              <MenuText selected={endPath === route}>{INTRO_ROUTES[route]}</MenuText>
            </Link>
          );
        })}
      </MenuLayout>

      <HamburgerMenuWrapper
        openMenu={openMenu}
        onClick={() => setOpenMenu((prevState) => !prevState)}
      >
        <LogoImage
          src={
            openMenu
              ? '/resources/icons/close-thin.svg'
              : '/resources/icons/hamburger-mini-menu.svg'
          }
          alt={'Hamburger Menu'}
          layout={'fill'}
        />
      </HamburgerMenuWrapper>

      {openMenu
        ? createPortal(
            <HamburgerMenu closeMenu={closeMenu} />,
            document.getElementsByTagName('body')[0],
          )
        : null}
    </GNBContainer>
  );
};

export default GlobalNavigationBar;

const GNBContainer = styled.header<{ openMenu: boolean }>`
  ${Layout.flexRowBetweenCenter};
  width: 100%;
  padding: 0 160px;
  height: 80px;
  background-color: ${({ openMenu }) => (openMenu ? '#fff5d9' : Color.White)};
  position: ${({ openMenu }) => (openMenu ? 'fixed' : 'block')};
  z-index: 1;

  @media (max-width: 1200px) {
    padding: 0 5vw;
  }

  @media (max-width: ${BREAK_POINT}px) {
    padding: 0 34px 0 36px;
  }
`;

const Link = styled(_Link)`
  text-decoration: none;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 118px;
  height: 40px;

  @media (max-width: ${BREAK_POINT}px) {
    width: 80px;
    height: 27px;
  }
`;

const LogoImage = styled(Image)`
  cursor: pointer;
`;

const MenuLayout = styled.section`
  ${Layout.flexRowCenter};
  gap: 24px;

  @media (max-width: ${BREAK_POINT}px) {
    display: none;
  }
`;

const MenuText = styled.p<{ selected?: boolean }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;

  text-align: center;
  letter-spacing: -0.01em;
  color: ${Color.Gray8};

  cursor: pointer;

  ${({ selected }) => {
    if (selected) {
      return `
  font-weight: 600;
  color: ${Color.Black};
  `;
    }
  }}
`;

const VR = styled.div`
  width: 1.5px;
  height: 16px;
  background-color: ${Color.Gray6};
`;

const HamburgerMenuWrapper = styled.div<{ openMenu: boolean }>`
  display: none;

  @media (max-width: ${BREAK_POINT}px) {
    position: relative;
    display: block;
    width: ${({ openMenu }) => (openMenu ? 24 : 36)}px;
    height: 36px;
    margin-right: ${({ openMenu }) => (openMenu ? 6 : 0)}px;
  }
`;
