import Image from 'next/legacy/image';
import styled from 'styled-components';

import { Color } from 'src/styles/colors';
import { BREAK_POINT } from 'src/constants/intro';
import { Layout } from 'src/styles/layout';

const MMDC_FEATURES = [
  {
    imageUrl: '/resources/intro/home-foryou.jpeg',
    title: 'Personalized Playdates',
    description:
      'Get playdate recommendations based on your pup’s unique size, breed, energy, and personality.',
  },
  {
    imageUrl: '/resources/intro/home-explore.jpeg',
    title: 'Discover Nearby Dogs',
    description:
      'Explore detailed profiles of dogs in your area and connect with new pawrents and pawfriends.',
  },
  {
    imageUrl: '/resources/intro/home-playdates.jpeg',
    title: 'Join & Host Playdates',
    description:
      'Attend or schedule your own fun-filled playdate with the dog lovers in your community.',
  },
] as const;

const WelcomeMMDC = () => {
  return (
    <>
      <WelComeTitle>{'Welcome to MMDC -\nthe ultimate dog social app '}</WelComeTitle>

      <FeaturesLayout>
        {MMDC_FEATURES.map((feature) => (
          <FeatureItem key={feature.title}>
            <Image src={feature.imageUrl} alt={`${feature.title} image`} width={400} height={400} />
            <FeatureTitle>{feature.title}</FeatureTitle>
            <FeatureSpan>{feature.description}</FeatureSpan>
          </FeatureItem>
        ))}
      </FeaturesLayout>
    </>
  );
};

export default WelcomeMMDC;

const WelComeTitle = styled.h2`
  font-weight: 600;
  font-size: 32px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${Color.Black};
  white-space: pre-wrap;
  padding-top: 108px;
  padding-bottom: 92px;

  @media (max-width: ${BREAK_POINT}px) {
    font-size: 21px;
    padding-top: 16px;
    padding-bottom: 40px;
  }
`;

const FeaturesLayout = styled.section`
  ${Layout.flexRowCenterStart};
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 6vw 180px;

  @media (max-width: ${BREAK_POINT}px) {
    ${Layout.flexColCenter};
    gap: 60px;
    padding-bottom: 60px;
  }
`;

const FeatureItem = styled.div`
  ${Layout.flexColCenter};
  padding: 0 3vw;
  flex: 1;
`;

const FeatureTitle = styled.p`
  font-weight: 600;
  font-size: 28px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #000000;
  padding-top: 28px;

  @media (max-width: ${BREAK_POINT}px) {
    font-size: 21px;
  }
`;

const FeatureSpan = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${Color.Black};
  padding-top: 12px;
`;
