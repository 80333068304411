import Image from 'next/legacy/image';
import styled from 'styled-components';

import { Layout } from 'src/styles/layout';
import { BREAK_POINT } from 'src/constants/intro';
import { pushEventToGTM } from 'src/utils/gtm';
import { pushEventToAmplitude } from '../../utils/amplitude';
import { APP_STORE_LINK, GOOGLE_PLAY_STORE_LINK } from 'src/constants/link';

const APP_STORE_EVENT_NAME = 'click_appstore';
const PLAY_STORE_EVENT_NAME = 'click_playstore';

const AppDownloadButtons = () => {
  return (
    <ButtonLayout>
      <ButtonImage
        src={'/resources/intro/app-store.png'}
        alt={'app store button'}
        width={149}
        height={50}
        onClick={() => {
          pushEventToAmplitude(APP_STORE_EVENT_NAME);
          pushEventToGTM(APP_STORE_EVENT_NAME);
          window.open(APP_STORE_LINK);
        }}
      />
      <ButtonImage
        src={'/resources/intro/google-play.png'}
        alt={'google play button'}
        width={161}
        height={50}
        onClick={() => {
          pushEventToAmplitude(PLAY_STORE_EVENT_NAME);
          pushEventToGTM(PLAY_STORE_EVENT_NAME);
          window.open(GOOGLE_PLAY_STORE_LINK);
        }}
      />
    </ButtonLayout>
  );
};

export default AppDownloadButtons;

const ButtonLayout = styled.div`
  ${Layout.flexRowCenter};
  gap: 13px;
  padding-top: 20px;

  @media (max-width: ${BREAK_POINT}px) {
    gap: 8px;
  }
`;

const ButtonImage = styled(Image)`
  cursor: pointer;
  user-select: none;
  -webkit-user-drag: none;

  transition: all 0.2s;
  opacity: 1;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    transform: scale(0.98);
    opacity: 0.8;
  }
`;
