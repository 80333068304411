import Image from 'next/legacy/image';
import styled from 'styled-components';

import { Layout } from 'src/styles/layout';
import { Color } from 'src/styles/colors';
import { BREAK_POINT } from 'src/constants/intro';
import InstaFeedItem from './InstaFeedItem';
import { useMemo } from 'react';

const IMAGE_LENGTH_PER_ROW = 2;
const MAX_IMAGE_SIZE = 320;

interface Props {
  data: any[];
}

const FeaturedPawfriends = (props: Props) => {
  const firstRowData = useMemo(() => props.data.slice(0, IMAGE_LENGTH_PER_ROW), [props.data]);
  const secondRowData = useMemo(() => props.data.slice(IMAGE_LENGTH_PER_ROW), [props.data]);

  if (props.data.length < 1) {
    return null;
  }

  return (
    <PawfriendsLayout>
      <PawfriendsTitle>{'Featured Pawfriends '}</PawfriendsTitle>
      <PawfriendsText>
        {'Checkout the dogs of MMDC and their\nplaydate expierences.'}
      </PawfriendsText>

      <ImageLayout>
        <ImageRawLayout>
          {firstRowData.map((_, index) => (
            <Item key={index}>
              <InstaFeedItem feed={_} width="300px" height="300px" />
            </Item>
          ))}
        </ImageRawLayout>
        <ImageRawLayout>
          {secondRowData.map((_, index) => (
            <Item key={index}>
              <InstaFeedItem feed={_} width="300px" height="300px" />
            </Item>
          ))}
        </ImageRawLayout>
      </ImageLayout>
    </PawfriendsLayout>
  );
};

export default FeaturedPawfriends;

const PawfriendsLayout = styled.section`
  ${Layout.flexColCenter};
  width: 100%;
  padding: 90px 10vw;
  background-color: #f5f5f5;

  @media (max-width: ${BREAK_POINT}px) {
    padding: 64px 10vw;
  }
`;

const PawfriendsTitle = styled.h2`
  font-weight: 600;
  font-size: 36px;
  line-height: 140%;
  letter-spacing: -0.01em;
  text-align: center;
  color: ${Color.Black};

  @media (max-width: ${BREAK_POINT}px) {
    font-size: 21px;
  }
`;

const PawfriendsText = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 129%;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${Color.Gray8};

  padding-top: 16px;
  padding-bottom: 56px;

  @media (max-width: ${BREAK_POINT}px) {
    font-size: 16px;
  }
`;

const ImageLayout = styled.div`
  ${Layout.flexRowCenter};
  width: 100%;
  gap: 38px;

  @media (max-width: ${BREAK_POINT}px) {
    ${Layout.flexColCenter};
  }

  @media (max-width: 1300px) {
    ${Layout.flexColCenter};
  }

  @media (max-width: 1360px) {
    gap: 16px;
  }
`;

const ImageRawLayout = styled.div`
  ${Layout.flexRowCenter};
  gap: 38px;

  @media (max-width: 1360px) {
    gap: 16px;
  }

  @media (max-width: 650px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Item = styled.div`
  width: 300px;
  height: 300px;
`;
