import Head from 'next/head';
import { FC } from 'react';

interface Props {
  url?: string;
  title?: string;
  description?: string;
  metaImageUrl?: string;
  metaTitle?: string;
}

const isDev = process.env.NODE_ENV === 'development';

const MainHead: FC<Props> = ({
  url,
  title,
  description = 'Find the ideal playdate for your pup! MMDC helps you find the best playmate that can improve your dog’s social skills and live a stress-free life. What’s more, MMDC makes it easy to find trusted dog services that other dog owners love. Share and discover the dog daycares, dog groomers, dog vets, and even favorite dog-friendly places around you. Help your dog live their best life by joining MMDC!',
  metaTitle = title,
  metaImageUrl = 'https://momo-public-img.s3.us-west-1.amazonaws.com/meetmydogchallenge.png',
}) => {
  return (
    <Head>
      {title && <title>{title}</title>}
      {(metaTitle || title) && (
        <>
          <meta property="og:site_name" content={metaTitle} />
          <meta property="og:title" content={metaTitle} />
          <meta name="twitter:title" content={metaTitle} />
        </>
      )}
      {url && (
        <>
          <meta property="og:url" content={url} />
          <meta name="twitter:url" content={url} />
          <link rel="canonical" href={url}></link>
        </>
      )}

      <meta name="description" content={description} />
      <meta name="keywords" content="meetmydogchallenge" />
      {isDev && <meta name="robots" content="noindex,nofollow" />}
      <meta name="googlebot" content="nosnippet" />

      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={metaImageUrl} />
      <meta property="og:locale" content="en_US" />
      <meta property="fb:app_id" content="596612211444132" />
      <meta name="twitter:card" content="summary" />
      <meta property="twitter:description" content={description} />
      <meta name="twitter:image" content={metaImageUrl} />
    </Head>
  );
};

export default MainHead;
