import styled from 'styled-components';
import Image from 'next/legacy/image';

import { Layout } from 'src/styles/layout';

const ICON_LIST = [
  {
    src: '/resources/icons/instagram-white.svg',
    linkUrl: 'https://www.instagram.com/meetmydogchallenge/',
    alt: 'instagram icon',
    width: 15,
    height: 15,
  },
  {
    src: '/resources/icons/clip-white.svg',
    linkUrl: 'https://www.crunchbase.com/organization/momo-project',
    alt: 'clip icon',
    width: 18,
    height: 9,
  },
  {
    src: '/resources/icons/linkedin-white.svg',
    linkUrl: 'https://www.linkedin.com/company/momo-project-inc/',
    alt: 'linkedin icon',
    width: 13,
    height: 12,
  },
] as const;

const SNSIconList = () => {
  return (
    <IconListContainer>
      {ICON_LIST.map((icon) => (
        <a key={icon.src} target="_blank" rel="noopener noreferrer" href={icon.linkUrl}>
          <IconWrapper>
            <Image src={icon.src} alt={icon.alt} height={icon.height} width={icon.width} />
          </IconWrapper>
        </a>
      ))}
    </IconListContainer>
  );
};

export default SNSIconList;

const IconListContainer = styled.div`
  ${Layout.flexRowCenter};
  width: 100%;
  gap: 8px;
`;

const IconWrapper = styled.div`
  ${Layout.flexRowCenter};
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #000000;
  cursor: pointer;
`;
