import { useRef } from 'react';
import Image from 'next/legacy/image';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper';
import 'swiper/css';

import { Layout } from 'src/styles/layout';
import { BREAK_POINT } from 'src/constants/intro';
import { Color } from 'src/styles/colors';
import resources from 'src/resources';

const PAWRENTS_REVIEWS = [
  {
    content:
      '“Starfire has grown wary of being around multiple dogs since she unfortunately got bitten at the park. MMDC has been a great tool in helping her become more comfortable around dogs again through small, controlled playdates.”',
    pawrent: 'Starfire',
  },
  {
    content:
      'I’ve met so many humans and pups that are in my area and who have great recommendations for cool places to bond with Dakota. It’s been lovely✨”',
    pawrent: 'Dakota',
  },
  {
    content:
      '"MMDC helped my dog make friends with dogs her size and age, and me with their pawrents 😆 Keiko\'s become a social butterfly since joining, and it\'s so helpful knowing their compatibility before the playdate."',
    pawrent: 'Keiko',
  },
] as const;

const PawrentReviews = () => {
  const swiperRef = useRef<SwiperClass | null>(null);

  const handleSwiper = (swiperInstance: SwiperClass | null) => {
    if (swiperInstance) {
      swiperRef.current = swiperInstance;
    }
  };

  const goNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  return (
    <>
      <PawrentReviewsTitle>{'Loved By Dogs and Pawrents Alike'}</PawrentReviewsTitle>

      <SwiperContainer
        slidesPerView={1}
        spaceBetween={28}
        grabCursor={true}
        breakpoints={{ 800: { slidesPerView: 2 }, 1200: { slidesPerView: 3 } }}
        style={{ overflow: 'hidden', margin: '0 10vw' }}
        onSwiper={handleSwiper}
      >
        {PAWRENTS_REVIEWS.map((review) => (
          <SwiperSlide key={review.pawrent} style={{ height: 'auto' }}>
            <ReviewBox>
              <div>
                <Image
                  src={'/resources/intro/quotation-marks.svg'}
                  alt="quotation marks icon"
                  width={26}
                  height={20}
                />
                <ReviewText>{review.content}</ReviewText>
              </div>
              <PawrentText>{`${review.pawrent}’s Pawrent`}</PawrentText>
            </ReviewBox>
          </SwiperSlide>
        ))}

        <ButtonsLayout>
          <StyledButton style={{ transform: 'rotate(180deg)' }} onClick={goPrev} />
          <StyledButton onClick={goNext} />
        </ButtonsLayout>
      </SwiperContainer>
    </>
  );
};

export default PawrentReviews;

const PawrentReviewsTitle = styled.h2`
  margin: 0 auto;
  padding-top: 112px;
  padding-bottom: 96px;
  font-weight: 600;
  font-size: 36px;
  line-height: 140%;
  letter-spacing: -0.01em;
  text-align: center;
  color: ${Color.Black};

  @media (max-width: ${BREAK_POINT}px) {
    font-size: 21px;
    max-width: 200px;
    padding-top: 64px;
    padding-bottom: 48px;
  }
`;

const ReviewBox = styled.div`
  ${Layout.flexColBetweenStart};
  padding: 19px 21px;
  background-color: #fff9e2;
  border-radius: 16px;
  height: 100%;
`;

const ReviewText = styled.p`
  padding-top: 17px;
  padding-bottom: 45px;
  font-weight: 400;
  font-size: 17px;
  line-height: 140%;
  letter-spacing: -0.01em;
  color: ${Color.Black};
`;

const PawrentText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.01em;
  color: ${Color.Gray7};
`;

const SwiperContainer = styled(Swiper)`
  .swiper-wrapper {
    display: flex;
    align-items: stretch;
  }
  padding-bottom: 155px;

  @media (max-width: ${BREAK_POINT}px) {
    padding-bottom: 64px;
  }
`;

const ButtonsLayout = styled.div`
  display: none;

  @media (max-width: ${BREAK_POINT}px) {
    ${Layout.flexRowCenter};
    padding-top: 20px;
    gap: 12px;
  }
`;

const StyledButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: transparent;
  background-image: url(${resources.icons.swipeNext});
  background-size: 40px;
  background-position: center;
  background-repeat: no-repeat;
`;
