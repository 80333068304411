import styled from 'styled-components';

import { Layout } from 'src/styles/layout';
import { BREAK_POINT } from 'src/constants/intro';
import { Color } from 'src/styles/colors';

import AppDownloadButtons from '../AppDownloadButtons';

const HeaderAppDownload = () => {
  return (
    <AppDownloadLayout>
      <Thumbnail />
      <AppDownloadBox>
        <AppDownloadTitle>{'Find your dog’s perfect playdate'}</AppDownloadTitle>
        <AppDownloadDescription>
          {'Discover personalized playdates for your pup’s size, energy, and personality. '}
        </AppDownloadDescription>
        <AppDownloadButtons />
      </AppDownloadBox>
    </AppDownloadLayout>
  );
};

export default HeaderAppDownload;

const AppDownloadLayout = styled.section`
  ${Layout.flexColCenter};
  position: relative;
  margin: 0 10vw;

  @media (max-width: ${BREAK_POINT}px) {
    margin: 0 36px;
  }
`;

const Thumbnail = styled.div`
  ${Layout.flexRowCenter};
  background-image: url('/resources/intro/home-thumbnail@2x.jpeg');
  background-blend-mode: overlay, normal;
  background-size: cover;
  background-position: center center;

  border-radius: 10px;
  width: 100%;
  max-width: 1280px;
  height: 0;
  padding-top: 60%;

  @media (min-width: 1600px) {
    padding-top: 720px;
  }

  @media (max-width: ${BREAK_POINT}px) {
    background-image: url('/resources/intro/home-thumbnail.jpeg');
  }
`;

const AppDownloadBox = styled.div`
  ${Layout.flexColCenter};
  width: 40%;
  max-width: 600px;
  min-width: 300px;

  border-radius: 6px;
  gap: 12px;
  padding: 32px 24px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  background-color: rgba(255, 255, 255, 0.8);

  @media (max-width: ${BREAK_POINT}px) {
    position: relative;
    top: 0;
    left: 0;
    transform: translate3d(0, 0, 0);
    padding: 40px 0;
    width: 100%;
    min-width: auto;
  }
`;

const AppDownloadTitle = styled.h2`
  font-weight: 600;
  font-size: 28px;
  line-height: 124%;
  text-align: center;
  color: ${Color.Black};

  @media (max-width: ${BREAK_POINT}px) {
    font-size: 20px;
    padding-bottom: 20px;
  }
`;

const AppDownloadDescription = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 129%;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${Color.Gray8};

  @media (max-width: ${BREAK_POINT}px) {
    font-size: 16px;
  }
`;
