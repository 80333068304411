import { useRouter } from 'next/router';
import styled from 'styled-components';
import _Link from 'next/link';
import { FC } from 'react';

import { Layout } from 'src/styles/layout';
import { Color } from 'src/styles/colors';
import useJoiningStore from 'src/states/joining';
import { INTRO_ROUTES } from './GlobalNavigationBar';
import SNSIconList from './SNSIconList';

type HamburgerMenuProps = {
  closeMenu: () => void;
};

// 문자열에서 첫 번째 '/' 바로 뒤에 '/blog'가 오는지 체크하고, 두 번째 '/'부터는 모두 무시
const PATTERN = /^[^\/]*\/blog.*/;

const HamburgerMenu: FC<HamburgerMenuProps> = ({ closeMenu }) => {
  const router = useRouter();
  const pathname = router.pathname;
  const endPath = PATTERN.test(pathname) ? 'blog' : pathname.split('/')[2];

  const showModal = useJoiningStore((state) => state.showModal);

  return (
    <MenuContainer>
      <Link href="/intro" onClick={closeMenu}>
        <MenuText selected={!endPath}>Home</MenuText>
      </Link>
      {Object.keys(INTRO_ROUTES).map((key) => {
        const route = key as keyof typeof INTRO_ROUTES;
        return (
          <Link key={route} href={`/intro/${route}`} onClick={closeMenu}>
            <MenuText selected={endPath === route}>{INTRO_ROUTES[route]}</MenuText>
          </Link>
        );
      })}

      <SNSIconListContainer>
        <SNSIconList />
      </SNSIconListContainer>
    </MenuContainer>
  );
};

export default HamburgerMenu;

const MenuContainer = styled.section`
  ${Layout.flexColCenter};
  position: fixed;
  z-index: 999;
  top: 80px;
  left: 0;
  width: 100%;
  height: calc(100vh - 80px);
  background-color: #fff5d9;
  padding-bottom: 80px;
`;

const Link = styled(_Link)`
  text-decoration: none;
`;

const MenuText = styled.p<{ selected?: boolean }>`
  margin: 3.7vw 0;

  font-weight: 400;
  font-size: 6.6vmin;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${Color.Gray8};

  cursor: pointer;

  ${({ selected }) => {
    if (selected) {
      return `
  font-weight: 600;
  color: ${Color.Black};
  `;
    }
  }}
`;

const SNSIconListContainer = styled.div`
  ${Layout.flexRowCenter};
  position: fixed;
  width: 100%;
  height: 55px;
  bottom: 25px;
  left: 0;
`;
