import { useEffect } from 'react';
import { useRouter } from 'next/router';
import axios from 'axios';
import { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next';
import crypto from 'crypto';
import AWS from 'aws-sdk';
import { Upload } from '@aws-sdk/lib-storage';
import { S3Client } from '@aws-sdk/client-s3';

import { getUserToken } from 'src/utils/auth';

import GlobalNavigationBar from 'src/components/intro/GlobalNavigationBar';
import FootNavigationBar from 'src/components/intro/FootNavigationBar';
import HomeHead from 'src/components/intro/home/HomeHead';
import HeaderAppDownload from 'src/components/intro/home/HeaderAppDownload';
import WelcomeMMDC from 'src/components/intro/home/WelcomeMMDC';
import AboutUs from 'src/components/intro/home/AboutUs';
import DogGroup from 'src/components/intro/home/DogGroup';
import PawrentReviews from 'src/components/intro/home/PawrentReviews';
import FeaturedPawfriends from 'src/components/intro/home/FeaturedPawfriends';
import AppDownloadBanner from 'src/components/intro/AppDownloadBanner';

interface Props {
  instaPhotos: any[];
}

const IntroPage: InferGetServerSidePropsType<typeof getServerSideProps> = ({
  instaPhotos,
}: Props) => {
  const router = useRouter();

  useEffect(() => {
    const token = getUserToken();

    if (!!token) {
      router.replace('/for-you').then();
    }
  }, []);

  return (
    <>
      <HomeHead />

      <GlobalNavigationBar />

      <HeaderAppDownload />
      <WelcomeMMDC />
      <AboutUs />
      <DogGroup />
      <PawrentReviews />
      <FeaturedPawfriends data={instaPhotos} />
      <AppDownloadBanner />

      <FootNavigationBar />
    </>
  );
};

async function fetchInstagramPost(limit: number, token: string) {
  let data;
  try {
    data = await axios
      .get(
        `https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption,is_shared_to_feed&limit=${limit}&access_token=${token}`,
      )
      .then((resp) => {
        return resp.data.data;
      });
  } catch (err) {
    console.log((err as any).response.data, 'fetch instagram error');
  }

  return data;
}

AWS.config.update({
  accessKeyId: process.env.AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY,
  region: 'us-west-1',
});

async function upload(file: Buffer, name: string, mimeType: string) {
  const upload = new Upload({
    client: new S3Client({ region: 'us-west-1' }),

    params: {
      ACL: 'public-read',
      Bucket: 'mmdc-web',
      Key: name,
      Body: file,
      ContentType: mimeType,
    },
  });
  return await upload.done();
}

async function refreshIntagramToken(curretToken: string) {
  const newToken = fetch(
    `https://graph.instagram.com/refresh_access_token?grant_type=ig_refresh_token&access_token=${curretToken}`,
  );
  const newTokenText = await (await newToken).json();
  return newTokenText;
}

async function fetchToken() {
  const keyStr = process.env.INSTAGRAM_TOKEN_KEY || '';
  const key = Buffer.from(keyStr, 'utf8').slice(0, 32);
  const encryptedToken = fetch('https://mmdc-web.s3.us-west-1.amazonaws.com/encrypted_it.txt');
  const encryptedTokenText = await (await encryptedToken).text();
  const decipher = crypto.createDecipheriv('aes-256-cbc', key, Buffer.alloc(16, 0));
  let decryptedData = decipher.update(encryptedTokenText, 'base64', 'utf8');
  decryptedData += decipher.final('utf8');
  return decryptedData;
}

async function encryptToken(token: string) {
  const keyStr = process.env.INSTAGRAM_TOKEN_KEY || '';
  const key = Buffer.from(keyStr, 'utf8').slice(0, 32);
  const cipher = crypto.createCipheriv('aes-256-cbc', key, Buffer.alloc(16, 0));
  let encryptedData = cipher.update(token, 'utf8', 'base64');
  encryptedData += cipher.final('base64');
  return encryptedData;
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  let data = [];
  try {
    const token = await fetchToken();
    const refreshed = await refreshIntagramToken(token);
    const encryptedToken = await encryptToken(refreshed.access_token);
    await upload(Buffer.from(encryptedToken), 'encrypted_it.txt', 'text/plain');
    data = await fetchInstagramPost(4, token);
  } catch (error) {
    console.log(error, 'upload s3 error');
  }
  return {
    props: { instaPhotos: data },
  };
};

export default IntroPage;
