import { User } from 'src/types/user';
import { create } from 'zustand';

interface JoiningStore {
  message: string | null;
  isShow: boolean;
  initial: 'signin' | 'signup';
  redirectUrl: string | null;
  onFihisnAuth: ((user: User) => Promise<unknown>) | null;
  showModal: (
    msg: string,
    redirectUrl?: string,
    onFinishAuth?: (user: User) => Promise<unknown>,
  ) => void;
  hideModal: () => void;
}

const useJoiningStore = create<JoiningStore>((set) => ({
  message: null,
  isShow: false,
  initial: 'signup',
  redirectUrl: null,
  onFihisnAuth: null,
  showModal: (message, redirectUrl, onFinish) => {
    sessionStorage.setItem('redirectUrlAfterAuth', redirectUrl ?? location.pathname);

    set({
      message,
      isShow: true,
      redirectUrl: redirectUrl ?? location.pathname,
      onFihisnAuth: onFinish ?? null,
    });
  },
  hideModal: () => set({ message: null, isShow: false }),
}));

export default useJoiningStore;
