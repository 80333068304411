import Image from 'next/legacy/image';
import styled from 'styled-components';
import { FC } from 'react';

import { Layout } from 'src/styles/layout';
import { BREAK_POINT } from 'src/constants/intro';
import { Color } from 'src/styles/colors';

import AppDownloadButtons from './AppDownloadButtons';

interface IntroHeaderProps {
  paddingTop?: number;
  backgroundColor?: keyof typeof Color;
  isResponsive?: boolean;
}

const AppDownloadBanner: FC<IntroHeaderProps> = ({
  paddingTop = 138,
  backgroundColor = 'Secondary',
  isResponsive = true,
}) => {
  if (!isResponsive) {
    return (
      <MAppDownloadBannerLayout paddingTop={paddingTop}>
        <MAppDownloadBannerBox backgroundColor={backgroundColor}>
          <MAppDownloadDescription>
            <MAppDownloadTitle>{'Download the MMDC App'}</MAppDownloadTitle>
            <AppDownloadButtons />
          </MAppDownloadDescription>
          <MAppDownloadImage
            src={'/resources/intro/new-home-mmdc-app.png'}
            width={444}
            height={462}
          />
        </MAppDownloadBannerBox>
      </MAppDownloadBannerLayout>
    );
  }

  return (
    <AppDownloadBannerLayout paddingTop={paddingTop}>
      <AppDownloadBannerBox backgroundColor={backgroundColor}>
        <AppDownloadDescription>
          <AppDownloadTitle>{'Download the MMDC App'}</AppDownloadTitle>
          <AppDownloadSpan>
            {'Join the dog lover’s community and watch your pup’s social life soar. '}
          </AppDownloadSpan>
          <AppDownloadButtons />
        </AppDownloadDescription>
        <AppDownloadImage src={'/resources/intro/new-home-mmdc-app.png'} width={444} height={462} />
      </AppDownloadBannerBox>
    </AppDownloadBannerLayout>
  );
};

export default AppDownloadBanner;

const MAppDownloadBannerLayout = styled.section<{ paddingTop?: number }>`
  ${Layout.flexColCenter};
  width: 100%;
  padding: ${({ paddingTop }) => (paddingTop ?? 0) * 0.5}px 4vw;
`;
const MAppDownloadBannerBox = styled.div<{ backgroundColor: keyof typeof Color }>`
  ${Layout.flexColCenter};
  flex-direction: column-reverse;
  padding: 32px 77px;
  gap: 24px;
  width: 100%;
  background-color: ${({ backgroundColor }) => Color[backgroundColor]};
  border-radius: 20px;
`;
const MAppDownloadImage = styled(Image)`
  flex: 4;
`;
const MAppDownloadDescription = styled.section`
  ${Layout.flexColCenter};
  flex: 6;
  min-width: 200px;
`;
const MAppDownloadTitle = styled.h2`
  font-size: 28px;
  font-weight: 600;
  line-height: 124%;
  letter-spacing: -0.01em;
  text-align: center;
  color: ${Color.Black};
`;

const AppDownloadBannerLayout = styled.section<{ paddingTop?: number }>`
  ${Layout.flexColCenter};
  width: 100%;
  padding: ${({ paddingTop }) => paddingTop}px 10vw ${({ paddingTop }) => paddingTop}px;

  @media (max-width: ${BREAK_POINT}px) {
    padding: ${({ paddingTop }) => (paddingTop ?? 0) * 0.5}px 8vw;
  }
`;
const AppDownloadBannerBox = styled.div<{ backgroundColor: keyof typeof Color }>`
  ${Layout.flexRowCenter};
  width: 100%;
  padding: 58px 77px;
  background-color: ${({ backgroundColor }) => Color[backgroundColor]};
  border-radius: 20px;
  gap: 128px;

  @media (max-width: 1200px) {
    gap: 64px;
  }

  @media (max-width: ${BREAK_POINT}px) {
    ${Layout.flexColCenter};
    padding: 32px 77px;
    gap: 24px;
    flex-direction: column-reverse;
  }
`;

const AppDownloadImage = styled(Image)`
  flex: 4;
`;

const AppDownloadDescription = styled.section`
  ${Layout.flexColEndStart};
  flex: 6;
  min-width: 250px;

  @media (max-width: ${BREAK_POINT}px) {
    ${Layout.flexColCenter};
    min-width: 200px;
  }
`;

const AppDownloadTitle = styled.h2`
  font-weight: 600;
  font-size: 64px;
  line-height: 124%;
  letter-spacing: -0.01em;
  color: ${Color.Black};

  @media (max-width: 1200px) {
    font-size: 42px;
  }

  @media (max-width: ${BREAK_POINT}px) {
    font-size: 28px;
    text-align: center;
    padding-top: 0px;
  }
`;

const AppDownloadSpan = styled.span`
  font-weight: 400;
  font-size: 24px;
  line-height: 129%;
  letter-spacing: -0.01em;
  color: ${Color.Gray8};
  padding-top: 16px;
  padding-bottom: 36px;

  @media (max-width: 1200px) {
    font-size: 20px;
  }

  @media (max-width: ${BREAK_POINT}px) {
    display: none;
  }
`;
