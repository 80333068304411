export const Color = {
  Primary: '#FFD43E',
  Secondary: '#FFF9E2',
  Gray10: '#1C1C1C',
  Gray8: '#565656',
  Gray7: '#838383',
  Gray6: '#BDBDBD',
  Gray5: '#CECECE',
  Gray4: '#DBDBDB',
  Gray2: '#F5F5F5',
  Gray1: '#FAFAFA',
  Black: '#303030',
  White: '#FFFFFF',
} as const;
