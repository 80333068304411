import MainHead from '../../MainHead';

const HomeHead = () => {
  const title = 'MMDC - Playdates, Join Groups & Meet Local Dog Lovers';
  const url = `${process.env.NEXT_PUBLIC_WEBSITE_URL}/intro`;
  const description =
    'Get started with MMDC today and help your dog socialize! Easily connect with other dog lovers and find compatible playdates based on the same size, same breed, and same energy level dogs in your area. Attend local dog meetups and schedule perfect playdates just for your dogs!';

  return <MainHead title={title} url={url} description={description} />;
};

export default HomeHead;
