import Link from 'next/link';
import styled from 'styled-components';

import { Color } from 'src/styles/colors';
import { BREAK_POINT } from 'src/constants/intro';

const LearnMoreButton = ({ href }: { href: string }) => {
  const goToScrollTop = () => {
    document?.getElementById('main-container')?.scrollTo(0, 0);
  };

  return (
    <Link href={href} legacyBehavior>
      <StyledLearnMoreButton onClick={goToScrollTop}>{'Learn more'}</StyledLearnMoreButton>
    </Link>
  );
};

export default LearnMoreButton;

const StyledLearnMoreButton = styled.button`
  border-radius: 13px;
  padding: 16px 24px;
  background-color: ${Color.White};
  border: 3px solid ${Color.Gray10};
  color: ${Color.Gray10};
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;

  &:hover {
    background-color: ${Color.Gray10};
    color: ${Color.White};
  }

  &:active {
    background-color: ${Color.Gray10};
    color: ${Color.White};
    transform: scale(0.98);
  }

  @media (max-width: ${BREAK_POINT}px) {
    padding: 12px 18px;
    border: 2px solid ${Color.Gray10};
  }
`;
