import axios from 'axios';
import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';

const TOKEN =
  'IGQVJXT1pJc3VjM0k3Q0xNak13Q2I4dEdNU1J4OG1MeFFxdXRCS0t1Q184aTVVTUhIQ1VrclR5SHU5TE5tMmhzYi01T2MzbFJKd2hicE1lUnhIeEpfdWhXRlpvUE9ZAZAkp6WGdZAX01Ndlg2WmFhRzVxXwZDZD';

async function getPermalinkFromId(id: string): Promise<string> {
  const result = await axios.get(
    `https://graph.instagram.com/${id}?fields=permalink&access_token=${TOKEN}`,
  );
  return result.data.permalink;
}

interface FeedProps {
  feed: {
    id: string;
    caption: string;
    media_type: 'VIDEO' | 'CAROUSEL_ALBUM' | string;
    media_url: string;
  };
  width: string;
  height: string;
}

const Feed: FC<FeedProps> = (props: FeedProps): ReactElement => {
  const { id, caption, media_type, media_url } = props.feed;
  const { width, height } = props;
  let post: ReactElement;

  switch (media_type) {
    case 'VIDEO':
      post = (
        <VideoWrapper
          width={width}
          height={height}
          onClick={async () => {
            const link = await getPermalinkFromId(id);
            window.open(link);
          }}
        >
          <Video
            width={width}
            height="auto"
            src={media_url}
            controls
            playsInline
            autoPlay
            loop
            muted
          />
        </VideoWrapper>
      );
      break;
    default:
      post = (
        <ImageWrapper
          width={width}
          height={height}
          onClick={async () => {
            const link = await getPermalinkFromId(id);
            window.open(link);
          }}
        >
          <Image width={width} height="auto" id={id} src={media_url} alt={caption} />
        </ImageWrapper>
      );
  }

  return <React.Fragment>{post}</React.Fragment>;
};

export default Feed;

const VideoWrapper = styled.div<{ width: string; height: string }>`
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  overflow: hidden;
`;

const Video = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ImageWrapper = styled.div<{ width: string; height: string }>`
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  overflow: hidden;
`;

const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
