import styled from 'styled-components';

import { Layout } from 'src/styles/layout';
import { Color } from 'src/styles/colors';
import { BREAK_POINT } from 'src/constants/intro';

import SNSIconList from './SNSIconList';

const FootNavigationBar = () => {
  return (
    <FooterContainer>
      <SNSIconListContainer>
        <SNSIconList />
      </SNSIconListContainer>

      <FooterText>{'Momo Project Inc. 2023'}</FooterText>
    </FooterContainer>
  );
};

export default FootNavigationBar;

const FooterContainer = styled.footer`
  ${Layout.flexColCenter};
  width: 100%;
  height: 240px;
  gap: 24px;

  @media (max-width: ${BREAK_POINT}px) {
    height: 170px;
  }
`;

const SNSIconListContainer = styled.div`
  ${Layout.flexRowCenter};
  width: 100%;
`;

const FooterText = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${Color.Gray8};

  @media (max-width: ${BREAK_POINT}px) {
    font-size: 16px;
  }
`;
