import Image from 'next/legacy/image';
import styled from 'styled-components';

import { Layout } from 'src/styles/layout';
import { Color } from 'src/styles/colors';
import { BREAK_POINT } from 'src/constants/intro';

import LearnMoreButton from './LearnMoreButton';

const DogGroup = () => {
  return (
    <DogGroupLayout>
      <DogGroupImage
        src={'/resources/intro/home-group.png'}
        alt={'dog group image'}
        width={516}
        height={560}
      />
      <DogGroupDescription>
        <DogGroupTitle>{'Create Your Dog Group'}</DogGroupTitle>
        <DogGroupSpan>
          {
            'Connect with a local community of dog lovers to plan exciting meetups, playdates, and events focusing on the most important part, the dogs! Learn more about hosting a Group on MMDC.'
          }
        </DogGroupSpan>
        <LearnMoreButton href={'/intro/groups'} />
      </DogGroupDescription>
    </DogGroupLayout>
  );
};

export default DogGroup;

const DogGroupLayout = styled.section`
  ${Layout.flexRowCenter};
  width: 100%;
  padding: 64px 10vw;
  background-color: #f5f5f5;
  gap: 175px;
  flex-direction: row-reverse;

  @media (max-width: 1200px) {
    gap: 100px;
  }

  @media (max-width: ${BREAK_POINT}px) {
    ${Layout.flexColCenter};
    gap: 28px;
  }
`;

const DogGroupDescription = styled.section`
  ${Layout.flexColEndStart};
  flex: 7;
  min-width: 250px;

  @media (max-width: ${BREAK_POINT}px) {
    ${Layout.flexColCenter};
  }
`;

const DogGroupTitle = styled.h2`
  font-weight: 600;
  font-size: 48px;
  line-height: 124%;
  letter-spacing: -0.01em;
  color: ${Color.Black};

  @media (max-width: 1200px) {
    font-size: 32px;
  }

  @media (max-width: ${BREAK_POINT}px) {
    font-size: 21px;
  }
`;

const DogGroupSpan = styled.span`
  font-weight: 400;
  font-size: 24px;
  line-height: 129%;
  letter-spacing: -0.01em;
  color: ${Color.Gray8};
  padding-top: 16px;
  padding-bottom: 32px;

  @media (max-width: 1200px) {
    font-size: 20px;
  }

  @media (max-width: ${BREAK_POINT}px) {
    font-size: 16px;
  }
`;

const DogGroupImage = styled(Image)`
  flex: 3;
`;
