import Image from 'next/legacy/image';
import styled from 'styled-components';

import { Layout } from 'src/styles/layout';
import { BREAK_POINT } from 'src/constants/intro';
import { Color } from 'src/styles/colors';

import LearnMoreButton from './LearnMoreButton';

const AboutUs = () => {
  return (
    <AboutUsLayout>
      <AboutUsImage src={'/resources/intro/home-about-us.jpeg'} width={520} height={368} />
      <AboutUsDescription>
        <AboutUsTitle>{'We are all dog lovers!'}</AboutUsTitle>
        <AboutUsSpan>
          {
            "At MMDC, we understand the importance of socialization for dogs, and that's why we've created a platform that helps pawrents live more fulfilling lives with their dogs."
          }
        </AboutUsSpan>
        <LearnMoreButton href={'/intro/about-us'} />
      </AboutUsDescription>
    </AboutUsLayout>
  );
};

export default AboutUs;

const AboutUsLayout = styled.section`
  ${Layout.flexRowCenter};
  width: 100%;
  padding: 0 10vw 172px;
  gap: 128px;

  @media (max-width: 1200px) {
    gap: 64px;
  }

  @media (max-width: ${BREAK_POINT}px) {
    ${Layout.flexColCenter};
    gap: 28px;
    padding-top: 40px;
    padding-bottom: 60px;
  }
`;

const AboutUsImage = styled(Image)`
  flex: 3;
`;

const AboutUsDescription = styled.section`
  ${Layout.flexColEndStart};
  flex: 7;
  min-width: 250px;

  @media (max-width: ${BREAK_POINT}px) {
    ${Layout.flexColCenter};
  }
`;

const AboutUsTitle = styled.h2`
  font-weight: 600;
  font-size: 48px;
  line-height: 124%;
  letter-spacing: -0.01em;
  color: ${Color.Black};

  @media (max-width: 1200px) {
    font-size: 32px;
  }

  @media (max-width: ${BREAK_POINT}px) {
    font-size: 21px;
  }
`;

const AboutUsSpan = styled.span`
  font-weight: 400;
  font-size: 24px;
  line-height: 129%;
  letter-spacing: -0.01em;
  color: ${Color.Gray8};
  padding-top: 16px;
  padding-bottom: 32px;

  @media (max-width: 1200px) {
    font-size: 20px;
  }

  @media (max-width: ${BREAK_POINT}px) {
    font-size: 16px;
  }
`;
